.stepperFormOuter {
  padding: 8px 120px;
  background: #fafafa;
  /* height: 100vh; */
}

.stepperFormInner {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  height: 85vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Inset shadow effect */
  transition: box-shadow 0.3s ease;
}

.backNextButtons {
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 5vh;
}

.contents {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.textFields {
  padding: 10px 0px;
}

.flexColumnDirection {
  display: flex;
  flex-direction: column;
}
