.search-bar {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    /* max-width: 300px; */
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  
  .search-bar-input {
    flex: 1;
    padding: 8px 12px;
    border: none;
    outline: none;
    font-size: 14px;
    color: #333;
  }
  
  .search-bar-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
  }
  
  .search-bar-button svg {
    width: 16px;
    height: 16px;
    stroke: #777;
  }
  
  .search-bar-input:focus {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
  
  .search-bar-button:hover {
    background-color: #f5f5f5;
  }
  