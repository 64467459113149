body {
  margin: 0;
  /* overflow: hidden; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#file-name {
  padding: 4px !important;
  height: 5vh !important;
  width: 23vw !important;
  border: none;
}


/* ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
} */


::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display:none;
}

.stepperFormInnerPopUp {
  background-color: white;
  border-radius: 10px;
  position: fixed;
  padding: 20px;
  top: 2vh;
  box-shadow: 0 0 8px 4px lightgray, 0 0 2px 2px lightgray;
}
.contents {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.backNextButtons {
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 5vh;
}
.introductionHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20vh;
  padding: 2rem;
}
[data-rsbs-overlay] {
  right: 0;
  bottom: 0;
  left: 25vw !important;
  width: 50vw !important;
  max-height: 60vh !important;
  max-width: 60vw !important;
  box-shadow: 0px 0px 7px 1px #c0c0c0;
}
[data-rsbs-is-dismissable="true"] [data-rsbs-scroll] > * {
  display: flex;
  justify-content: center;
  overflow: auto;
  width: 50vw;
  max-height: 50vh;
  /* margin-left: 10px; */
}
[data-rsbs-is-dismissable="true"]
  [data-rsbs-scroll]::-webkit-scrollbar-thumb:active {
  background-color: rgba(0, 0, 0, 0.4);
}
