.moduledashboard_bodies {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.header_dashboard_panel {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  width: 100%;
  height: 100%;
}

.moduledashboard_bodies_sidebar_component_show {
  animation: sidebar_show;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  margin-left: -250px;
  background-color: #f1f5ff;
}

@keyframes sidebar_show {
  100% {
    margin-left: 0px;
  }
}

.moduledashboard_bodies_sidebar_component_hide {
  animation: sidebar_hide;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  margin-left: 0px;
}

@keyframes sidebar_hide {
  100% {
    margin-left: -250px;
  }
}

.sidebaricon_hide {
  opacity: 0%;
  display: none;
  width: 10px;
}

.sidebaricon_show {
  width: 35px;
}

.master_div {
  display: flex;
  width: 100%;
}

.moduledashboard_panel {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  border-left: 1px solid #dcdcdcff;
  width: 100%;
  /* height: 93vh; */
}

.moduledashboard_user_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.563);
  padding-bottom: 5px;
}

.moduledashboard_user_image {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border: 15px solid white;
  border-color: hsla(200, 12%, 95%, 0.212);
  position: absolute;
  margin-left: -750px;
  margin-top: 0px;
}

.moduledashboard_moduledashboard_user_para {
  font-size: small;
  margin-left: 850px;
  margin-bottom: 5px;
  color: white;
  background-color: #5bc0de;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-transform: capitalize;
}

.moduledashboard_dashboard_bg {
  margin-left: 20px;
}

#sidebar_menu {
  /* position: absolute;
  margin-left: -3px;
  margin-top: -50px;
  padding-left: 12px;
  padding-right: 12px; */
  background-color: #f1f3f4;
  margin-left: 10px;
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;
}

#sidebar_menu:hover {
  background-color: #dcdcdcff;
}

.moduledashboard_dashboard_bg_heading {
  text-transform: capitalize;
  font-size: x-large;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #555555ff;
}

.moduledashboard_dashboard_breadcrumbs {
  position: absolute;
  margin-left: 1100px;
  margin-top: 80px;
}

.moduledashboard_footer {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #dcdcdcff;
  justify-content: flex-end;
  /* background-color: #d898beff; */
  position: absolute;
  margin-top: 620px;
  padding-left: 1149px;
}

.moduledashboard_footer_para {
  color: #dcdcdcff;
  margin-top: 8px;
  padding-right: 10px;
  font-size: small;
}

.breadcrumb_dashboard_link:hover {
  text-decoration: underline;
  color: #285cdcff;
  cursor: pointer;
}
