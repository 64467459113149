.person {
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.personinfo {
  display: flex;
}

.name {
  margin-top: 5px;
  margin-left: 8px;
}

.css-1xhypcz-MuiStack-root{
  padding-top: 0px !important ;
}

.table-card {
  padding: 1%;
  margin: 1%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px -1px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 10px;
}
.font {
  font-size: 18px;
  font-weight: 1000;
}