.my-div {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 2s, height 2s;
}

.my-div.visible {
  opacity: 1;
  height: auto;
  transition: opacity 2s, height 2s;
}
