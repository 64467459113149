.sidebar_settings {
    width: 250px;
    background-color: #f1f5ff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0px;
    color: #777777;
    height: 88vh;
  }
  
  .sidebar_application_div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .sidebar_brand_logo {
    width: 80px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
  }
  
  .sidebar_application_name {
    font-size: small;
    margin-bottom: 20px;
  }
  
  .sidebar_brandname {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    margin-left: 20px;
    font-size: small;
    letter-spacing: 1px;
    color: #5bc0deff;
    text-transform: capitalize;
  }
  
  #sidebar_brandname_logo {
    padding-top: 2px;
    margin-left: 2px;
  }
  
  .main_user_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .sidebar_icon {
    margin-right: 10px;
    color: #5bc0deff;
  }
  
  .sidebar_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    font-size: small;
    padding-left: 10px;
    border-bottom: 1px solid #f7f8fcff;
    padding-left: 20px;
  }
  
  .sidebar_highlight_flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: small;
    padding-left: 10px;
    border-bottom: 1px solid #f7f8fcff;
    padding-left: 20px;
    background-color: #f0f4fcff;
  }
  
  .sidebar_flex:hover {
    padding-right: unset;
    color: white;
    background-color: #5bc0deff;
    cursor: pointer;
  }
  
  .sidebar_flex:hover > .sidebar_icon {
    color: white;
  }
  
  #sidebar_dashboard_icon_appicon {
    width: 30px;
    color: #5bc0deff;
    margin-right: 10px;
  }
  
  .sidebar_flex:hover > #sidebar_dashboard_icon_appicon {
    color: white;
  }
  
  #sidebar_dashboard_icon {
    margin-right: 10px;
  }
  
  .sidebar_profile_para_appname {
    font-weight: 600;
    letter-spacing: 1px;
    color: #5bc0deff;
    width: max-content;
    padding-top: 15px;
  }
  
  .sidebar_flex:hover > .sidebar_profile_para_appname {
    color: white;
  }
  
  .sidebar_profile_para {
    width: max-content;
    padding-top: 15px;
  }
  
  #sidebar_icons {
    margin-right: 10px;
  }
  
  #sidebar_profile_child_icon {
    margin-left: 10px;
  }
  
  .sidebar_flex_submodule_show {
    width: 100%;
    font-size: small;
    border-bottom: 1px solid #f7f8fcff;
    animation: accordian;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    
  }
  
  @keyframes accordian {
    100% {
      padding-left: 40px;
    }
  }
  
  .sidebar_flex_submodule_show:hover {
    background-color: #5bc0deff;
    color: white;
    cursor: pointer;
  }
  
  .sidebar_flex_submodule_hide {
    display: none;
  
  }
  
  #sidebar_profile_child_icon {
    color: #5bc0deff;
    background-color: white;
    border: 4px solid white;
    border-radius: 20px;
    clip-path: circle();
  }
  
  #sidebar_profile_child_icon:hover {
    background-color: white;
    border: 4px solid white;
    border-radius: 20px;
  }
  
  .sidebar_flex_submodule_add {
    display: flex;
    margin-bottom: 10px;
    margin-top: 10px;
  
  }
  
  .sidebar_flex_submodule_view {
    display: flex;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  #sidebar_flex_submodule_add_icon {
    padding-top: 2px;
    margin-right: 10px;
  }
  
