.moduleFormPanel {
    background-color: #dcdcdc;
    height: 100vh;
    width: 100vw;
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto;
    background-image: radial-gradient(#a3a3a3, #eeeeee);
}

.NMFformpage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 20px 20px 20px;
    background-color: rgba(255, 255, 255, 0.507);
    width: 60vw;
    height: 90vh;
    border-radius: 10px;
    /* box-shadow: 1px 1px 10px 1px #a3a3a3ff; */
    overflow: scroll;
}

.NMFmoduleHeading {
    /* background-color: #dcdcdc; */
    border-bottom: 1px solid #dcdcdc;
    color: #444444;
    width: 100%;
    padding: 10px 0px 10px 10px;
    font-size: large;
    /* border-radius: 5px; */
}

.NMFDiv {
    display: flex;
    flex-direction: column;
    width: 90%;
    /* border: 1px solid #dcdcdc; */
    border-radius: 10px;
    padding: 20px;
    color: #555555ff;
    background-color: white;
    margin: 10px 0px 40px 40px;
    /* box-shadow: 1px 1px 10px 1px #a3a3a3ff; */
}

.NMFselectModule {
    width: 100%;
    border: 0px;
    border-radius: 5px;
    font-size: small;
    padding: 5px 0px 5px 5px;
    background-color: white;
    border: 1px solid #dcdcdc;
}

.NMFselectModule:focus {
    outline: 1px solid #5bc0deff;
}

.NMFmoduleNameDiv {
    margin-top: 20px;
}

.NMFmoduleName {
    width: 100%;
    border: 0px;
    border-radius: 5px;
    font-size: small;
    padding: 5px 0px 5px 5px;
    background-color: white;
    border: 1px solid #dcdcdc;
}

.NMFmoduleName:focus {
    outline: 1px solid #5bc0deff;
}

.NMFmoduleDiscriptionDiv {
    margin-top: 20px;
}

.NMFmoduleDiscription {
    width: 100%;
    border: 0px;
    border-radius: 5px;
    font-size: small;
    padding: 5px 0px 40px 5px;
    background-color: white;
    border: 1px solid #dcdcdc;
}

.NMFmoduleDiscription:focus {
    outline: 1px solid #5bc0deff;
}

.NMFmoduleIconDiv {
    margin-top: 20px;
}

.NMFmoduleIcon {
    width: 100%;
    border: 0px;
    border-radius: 5px;
    font-size: small;
    padding: 5px 0px 5px 5px;
    background-color: white;
    border: 1px solid #dcdcdc;
}

.NMFmodulePathDiv {
    margin-top: 20px;
}

.NMFmodulePath {
    width: 100%;
    border: 0px;
    border-radius: 5px;
    font-size: small;
    padding: 5px 0px 5px 5px;
    background-color: white;
    border: 1px solid #dcdcdc;
}

.NMFmodulePath:focus {
    outline: 1px solid #5bc0deff;
}

.NMFmoduleStatusDiv {
    margin-top: 20px;
}

.NMFmoduleStatus {
    width: 100%;
    border: 0px;
    border-radius: 5px;
    font-size: small;
    padding: 5px 0px 5px 5px;
    background-color: white;
    border: 1px solid #dcdcdc;
}

.NMFmoduleStatus:focus {
    outline: 1px solid #5bc0deff;
}

.NMFmodulebuttonDiv {
    margin-top: 40px;
}

.NMFmodulebutton {
    width: 100%;
    border: 0px;
    border-radius: 5px;
    font-size: small;
    padding: 5px 0px 5px 5px;
    background-color: #a4cde1ff;
    color: white;
}

.NMFmodulebutton:hover {
    background-color: #5bc0deff;
    cursor: pointer;
}

.NMFmodulebutton:active {
    outline: 0px;
    border: 0px;
}