.admindashboardContents {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #dcdcdc;
  height: 94vh;
  width: 100vw;
  justify-content: center;
}

.numberedadminapplication {
  display: flex;
  flex-direction: row;
  /* margin-top: 100px; */
}

.totaladminapplicationModules {
  margin: 0px 10px 0px 10px;
  background-color: white;
  padding: 20px 5px 20px 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 50px;
  background-color: white;
}

.totaladminapplicationModules:hover {
  cursor: pointer;
  box-shadow: 1px 1px 10px 1px #a3a3a3ff;
}

.applicationName {
  font-size: x-large;
  margin-top: 50px;
}

.activeadminapplication {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.adminapplicationsLink {
  color: #5bc0deff;
}

.adminapplicationsLink:hover {
  text-decoration: none;
  color: #5bc0deff;
}

.adminapplications {
  margin: 0px 10px 0px 10px;
 
  padding: 20px 5px 20px 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* text-decoration: none; */
}

.adminapplications:hover {
  cursor: pointer;
  box-shadow: 1px 1px 10px 1px #a3a3a3ff;
}

.adminapplications:hover > .adminapplicationpara {
  animation: letterSpacing 0.3s forwards ease-out;
}

.totaladminapplicationModules:hover > .adminapplicationpara {
  animation: letterSpacing 0.3s forwards ease-out;
}

@keyframes letterSpacing {
  100% {
    letter-spacing: 1px;
  }
}

.adminapplications:hover > #APplus {
  animation: rotationPlus 0.3s forwards ease-out;
}

@keyframes rotationPlus {
  100% {
    transform: rotate(90deg);
  }
}

.adminapplicationpara {
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
