.bodies {
  /* margin: 0px;
  padding: 0px; */
  font-family: Noto Sans;
  height: 100%;
  /* overflow-y: auto; */
}

.user_div {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #555555;
}

.user_name {
  font-size: xx-large;
  text-transform: capitalize;
  width: 50%;
  border-bottom: 2px solid;
  color: #fff;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  animation: breathing;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.user_description {
  font-size: medium;
  padding: 0.2rem 0rem;
  margin-top: 10px;
  text-transform: capitalize;
}

@keyframes breathing {
  0% {
  }

  50% {
    transform: translateY(3px);
  }
}

.user_name:hover {
  animation: underline;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes underline {
  100% {
    border-bottom: 2px solid #79b2a7;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.dynamic_icons_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 20px;
  text-align: center;
}

.dynamic_panel_module_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.module_name {
  /* background-color: rgba(255, 255, 255, 0.829); */
  color: #222222;
  font-size: small;
  text-transform: uppercase;
  letter-spacing: 1px;
  /* padding-top: 3px; */
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 100px;
}

.dynamic_icon {
  /* border-color:hsla(194, 66%, 61%, 0.229); */
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 120px;
  height: 90px;
  object-fit: cover;
  margin-left: 20px;
  margin-right: 20px;
  box-shadow: 1px 1px 5px 1px #555555;
}

.dynamic_icon:hover {
  box-shadow: 1px 1px 10px 1px #a3a3a3ff;
}

.dynamic_panel_footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: fixed;
  width: 100%;
  height: 20px;
  background-color: #f1f5ff;
  bottom: 0px;
  left: 0;
}

.dynamic_panel_footer_para {
  color: #000000;
  margin: 1px 0px;
  padding-right: 5px;
  font-size: small;
}

.addnewModulesHr {
  display: flex;
  justify-content: center;
  width: 300px;
  height: 5px;
  background-color: #00d1e4;
}

.addnewModules:hover ~ .addnewModulesHr {
  animation: hrgradient 0.3s forwards;
}

@keyframes hrgradient {
  100% {
    display: flex;
    justify-content: center;
    margin-left: 10px;
    width: 650px;
  }
}

.addnewModules {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 10px 0px 10px;
  padding: 20px 5px 20px 5px;
  height: 50px;
  cursor: pointer;
  justify-content: center;
  background-color: rgba(91, 192, 222, 0);
  letter-spacing: 1px;
  font-size: x-large;
  border-bottom: 4px solid white;
  /* box-shadow: 1px 50px 10px 1px #a3a3a3; */
}

.addnewModules:hover {
  animation: buttonExpansion 0.3s forwards;
}

@keyframes buttonExpansion {
  100% {
    color: white;
    font-size: x-large;
    width: 650px;
    /* box-shadow: 1px 50px 10px 1px #a3a3a3; */
  }
}
