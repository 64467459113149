.Dashboard {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  /* background-color: #f7f8fc; */
  height: 100%;
}

.Dashboard_module_div {
  margin-top: 20px;
  margin-bottom: 0px;
}

.Dashboard_module {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-left: 20px;
  margin-right: 20px;
  background-color: white;
  color: #777777;
  border-radius: 5px;
  text-transform: capitalize;
  font-size: small;
  padding-left: 10px;
  padding-right: 8px;
  width: 200px;
  border-radius: 10px;
}

.Dashboard_module_name {
  color: #5bc0de;
  font-size: large;
  margin-left: 30px;
  margin-bottom: 0px;
}

#dashboard_submodule_icon {
  margin-left: 40px;
  margin-bottom: 10px;
  size: 20px;
}

.Dashboardsubmodulescount {
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #777777;
}

.Dashboard_module_collective_span {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 40px;
}

.Dashboard_module_add_view {
  display: flex;
  flex-direction: column;
  position: absolute;
  cursor: pointer;
}

.Dashboard_module_icon_add {
  background-color: #a3a3a3ff;
  color: white;
  padding-top: 20px;
  padding-left: 0px;
  padding-right: 0px;
  height: 48px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 0%;
  opacity: 70%;
}

.Dashboard_module_icon_add:hover {
  background-color: #777777ff;
}

.Dashboard_module_add_view:hover > .Dashboard_module_icon_add {
  animation: addslide;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

@keyframes addslide {
  100% {
    color: white;
    padding-top: 10px;
    padding-left: 85px;
    padding-right: 85px;
    width: 100%;
  }
}

.Dashboard_module_icon_view {
  background-color: #a3a3a3ff;
  color: white;
  padding-top: 20px;
  padding-left: 0px;
  padding-right: 0px;
  height: 48px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 0%;
  opacity: 70%;
}

.Dashboard_module_icon_view:hover {
  background-color: #777777ff;
}

.Dashboard_module_add_view:hover > .Dashboard_module_icon_view {
  animation: viewslide;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes viewslide {
  100% {
    color: white;
    padding-top: 10px;
    padding-left: 85px;
    padding-right: 85px;
    width: 100%;
  }
}
