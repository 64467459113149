.masterDiv {
  display: flex;
  flex-direction: column;
  /* padding: 0px;
  margin: 0px;
  height: 100vh;
  width: 100vw;
  overflow-y: auto; */
}

.navbar {
  background-color: white;
}
[data-rsbs-backdrop] {
  background-color: #ffffff0d !important;
}
.Btm-sheet-up-btn {
  display: flex;
  justify-content: space-around;
  margin: 1rem 0rem;
  width: 100%;
}

.btm-sheet-lwr-btn {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
}

.dashboard {
  margin-top: 2vh;
}

.dashboardContents {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.numberedApplication {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 90px;
}

.invisibleModulename {
  display: flex;
}

/* .totalApplicationModules:hover > .invisibleModulename {
  display: flex;
} */

.totalApplicationModules {
  margin: 0px 10px 0px 10px;
  background-color: white;
  padding: 20px 5px 20px 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /* color: transparent; */
  height: 50px;
}

/* .totalApplicationModules:hover {
  color: #222222;
  cursor: pointer;
  box-shadow: 1px 1px 10px 1px #a3a3a3ff;
  animation: moduleExpansion 0.3s forwards;
}

@keyframes moduleExpansion {
  100% {
    width: 250px;
    font-size: small;
  }
} */

.activeApplication {
  display: flex;
  flex-direction: row;
  width: 100vw;
  justify-content: center;
  flex-wrap: wrap;
}

.applicationsLink {
  color: #5bc0deff;
}

.applicationsLink:hover {
  text-decoration: none;
  color: #5bc0deff;
}

.addnewApplicationsHr {
  display: flex;
  justify-content: center;
  width: 300px;
  height: 5px;
  background-color: #00d1e4;
}

.addnewApplications:hover ~ .addnewApplicationsHr {
  animation: hrgradient 0.3s forwards;
}

@keyframes hrgradient {
  100% {
    display: flex;
    justify-content: center;
    margin-left: 10px;
    width: 650px;
  }
}

.addnewApplications {
  margin: 60px 10px 0px 10px;
  padding: 20px 5px 20px 5px;
  width: 650px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(91, 192, 222, 0);
  color: white;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: x-large;
  border-bottom: 4px solid white;
  /* box-shadow: 1px 50px 10px 1px #a3a3a3; */
}

.addnewApplications:hover {
  animation: buttonExpansion 0.3s forwards;
}

@keyframes buttonExpansion {
  100% {
    color: white;
    font-size: x-large;
    width: 650px;
    /* box-shadow: 1px 50px 10px 1px #a3a3a3; */
  }
}

#APplus {
  margin-right: 10px;
}

.applications {
  margin: 0px 10px 0px 10px;
  padding: 20px 5px 20px 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.applications:hover {
  cursor: pointer;
  /* box-shadow: 1px 1px 10px 1px #a3a3a3ff; */
}

.applications:hover > .applicationpara {
  animation: letterSpacing 0.3s forwards ease-out;
}

/* .totalApplicationModules:hover > .applicationpara {
  animation: letterSpacing 0.3s forwards ease-out;
} */

@keyframes letterSpacing {
  100% {
    letter-spacing: 1px;
  }
}

.applications:hover > #APplus {
  animation: rotationPlus 0.3s forwards ease-out;
}

@keyframes rotationPlus {
  100% {
    transform: rotate(90deg);
  }
}

#moduleIcon {
  font-size: 40px;
  margin-right: 20px;
  color: #5bc0deff;
}

.applicationpara {
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  color: #00d1e4;
}

#applicationparaId {
  padding-bottom: 30px;
}
