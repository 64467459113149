.moduledashboard_bodies {
  margin: 0px;
  padding: 0px;
  font-family: Noto Sans;
}

.master_div {
  display: flex;
}

.main_panel {
  width: 1050px;
  background-color: #f7f8fcff;
  border-top-left-radius: 100px;
  display: flex;
  flex-direction: column;
}

.main_background {
  background-image: url(./Images/skyhigh.jpg);
  background-size: cover;
  width: 1050px;
  height: 100px;
  border-top-left-radius: 100px;
  animation: header_motion;
  animation-duration: 60s;
  animation-iteration-count: infinite;
}

@keyframes header_motion {
  100% {
    background-position-y: -1000px;
  }
}

.main_user_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.563);
  padding-bottom: 5px;
}

.main_user_image {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border: 15px solid white;
  border-color: hsla(200, 12%, 95%, 0.212);
  position: absolute;
  margin-left: -750px;
  margin-top: -70px;
}

.moduledashboard_main_user_para {
  margin-top: 10px;
  margin-left: -530px;
  margin-bottom: 0px;
  color: #555555;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}

.save_cancel_button {
  display: flex;
  margin-top: -20px;
  margin-left: 850px;
  margin-bottom: 10px;
}

.cancel_button {
  margin-right: 10px;
  background-color: white;
  border: none;
  color: #555555;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
}

.save_button {
  background-color: #5bc0deff;
  color: white;
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
}

.main_details {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  width: 950px;
  background-color: white;
  border-radius: 10px;
  margin-top: 50px;
  padding-bottom: 25px;
}

.moduledashboard_name {
  font-family: Fira + Sans;
  font-size: small;
  background-color: white;
  border-radius: 5px;
  margin-top: 20px;
  width: 900px;
  font-weight: lighter;
  border: none;
  margin-left: 15px;
}

.moduledashboard_name:focus {
  outline: none;
}

.moduledashboard_email {
  font-family: Fira + Sans;
  font-size: small;
  background-color: white;
  border-radius: 5px;
  width: 900px;
  font-weight: lighter;
  border: none;
  margin-left: 15px;
  margin-top: 10px;
}

.moduledashboard_email:focus {
  outline: none;
}

.moduledashboard_contact {
  font-family: Fira + Sans;
  font-size: small;
  background-color: white;
  border-radius: 5px;
  width: 900px;
  font-weight: lighter;
  border: none;
  margin-left: 15px;
  margin-top: 10px;
}

.moduledashboard_contact:focus {
  outline: none;
}
