.new-add {
  text-align: center;
  justify-content: center;
  text-align: left;
  background-color: white;
  border-radius: 2%;
}

.cross {
  font-size: 20px;
  cursor: pointer;
}

.board {
  text-align: left;
  margin-bottom: 15px;
  justify-content: space-between;
  display: flex;
}
.input-field {
  margin-bottom: 30px;
}

.person {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.personinfo {
  display: flex;
  margin-left: 15px;
}

.name {
  margin-bottom: -2px;
  width: 200px;
}
.cross {
  font-size: 20px;
}
.share {
  color: white;
  background-color: rgb(71, 71, 216);
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 9.5px 14px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 9.5px 14px !important;
  width: 230px !important;
}
.css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
  margin-bottom: -0.5rem;
}
