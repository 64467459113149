.admindashboardContents {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #dcdcdc;
  height: 100vh;
  width: 100vw;
}

.numberedadminapplication {
  display: flex;
  flex-direction: row;
  margin-top: 100px;
}

.totaladminapplicationModules {
  margin: 0px 10px 0px 10px;
  background-color: white;
  padding: 20px 5px 20px 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 50px;
  background-color: white;
}

.totaladminapplicationModules:hover {
  cursor: pointer;
  box-shadow: 1px 1px 10px 1px #a3a3a3ff;
}

.applicationName {
  font-size: x-large;
  margin-top: 50px;
}

.activeadminapplication {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.adminapplicationsLink {
  color: #5bc0deff;
}

.adminapplicationsLink:hover {
  text-decoration: none;
  color: #5bc0deff;
}

.adminapplications {
  margin: 0px 10px 0px 10px;
  background-color: white;
  padding: 20px 5px 20px 5px;
  border-radius: 5px;
  width: 150px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  /* text-decoration: none; */
}

.adminapplications:hover {
  cursor: pointer;
  box-shadow: 1px 1px 10px 1px #a3a3a3ff;
}

.adminapplications:hover > .adminapplicationpara {
  animation: letterSpacing 0.3s forwards ease-out;
}

.totaladminapplicationModules:hover > .adminapplicationpara {
  animation: letterSpacing 0.3s forwards ease-out;
}

@keyframes letterSpacing {
  100% {
    letter-spacing: 1px;
  }
}

.adminapplications:hover > #APplus {
  animation: rotationPlus 0.3s forwards ease-out;
}

@keyframes rotationPlus {
  100% {
    transform: rotate(90deg);
  }
}

.adminapplicationpara {
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.activeSubmodule {
  display: flex;
  flex-direction: row;
}

.submodules {
  margin: 0px 10px 0px 10px;
  padding: 20px 5px 20px 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modules {
  margin: 0px 10px 0px 10px;
  padding: 20px 5px 20px 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.submodules:hover {
  cursor: pointer;
  /* box-shadow: 1px 1px 10px 1px #a3a3a3ff; */
}

.submodules:hover > .submodulepara {
  animation: letterSpacing 0.3s forwards ease-out;
}

.submodulepara {
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  color: 00d1e4;
}

.addnewSubmodules:hover ~ .addnewSubmodulesHr {
  animation: hrgradient 0.3s forwards;
}

@keyframes hrgradient {
  100% {
    display: flex;
    justify-content: center;
    margin-left: 10px;
    width: 650px;
  }
}

.addnewSubmodules {
  margin: 60px 10px 0px 10px;
  padding: 20px 5px 20px 5px;
  width: 650px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(91, 192, 222, 0);
  color: #00d1e4;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: x-large;
  border-bottom: 4px solid white;
  /* box-shadow: 1px 50px 10px 1px #a3a3a3; */
}

.addnewSubmodules:hover {
  animation: buttonExpansion 0.3s forwards;
}

@keyframes buttonExpansion {
  100% {
    color: #00d1e4;
    font-size: x-large;
    width: 650px;
    /* box-shadow: 1px 50px 10px 1px #a3a3a3; */
  }
}

#submoduleparaId {
  padding-bottom: 30px;
}

.addnewSubmodulesHr {
  display: flex;
  justify-content: center;
  width: 300px;
  height: 5px;
  background-color: #00d1e4;
}
