.AdminOutlet{
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
    /* height: 100vh;
    width: 100vw; */
    background-color: #dcdcdcff;
}

.AOnavbar {
    display: flex;
    justify-content: space-around;
    background-color: white;
    padding: 0.5rem 1rem;
}
