.Profile_bodies {
    margin: 0px;
    padding: 0px;
    font-family: Noto Sans;
}

.master_div {
    display: flex;
}

.main_panel {
    width: 1050px;
    background-color: #f7f8fcff;
    border-top-left-radius: 100px;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
}

.main_background {
    background-image: url(./Images/skyhigh.jpg);
    background-size: cover;
    width: 1050px;
    height: 100px;
    border-top-left-radius: 100px;
    animation: header_motion;
    animation-duration: 60s;
    animation-iteration-count: infinite;
}

@keyframes header_motion {
    100% {
        background-position-y: -1000px;
    }
}

.main_user_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.563);
    padding-bottom: 5px;
}

.main_user_para {
    font-size: small;
    margin-top: 15px;
    margin-left: -800px;
    margin-bottom: 8px;
    color: #d898be;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
}

.new_project_icon {
    margin-right: 10px;
    color: #d898be;
}

.new_project_entries_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: 50px;
    width: 950px;
    background-color: white;
    border-radius: 10px;
    margin-top: 50px;
    padding-bottom: 25px;
}

.entry1_icon {
    margin-left: 10px;
    margin-right: 10px;
}

.new_project_entry1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: Noto Sans;
    margin-left: 30px;
    margin-top: 30px;
    padding-right: 10px;
    height: 40px;
    background-color: #f7f8fc;
    color: #777777;
    cursor: pointer;
    border: 0px;
    border-radius: 5px;
    height: 50px;
}

.new_project_entry1_label {
    font-family: Noto Sans;
    font-size: small;
}

.new_project_entry1:hover {
    box-shadow: 1px 1px 10px 0.1px #999999;
}

/* input1 */
.new_project_entry1_input {
    height: 30px;
    border: 0px;
    padding-left: 10px;
    border-radius: 5px;
    margin-left: 20px;
    width: 150px;
}

.new_project_entry1_input:focus {
    font-family: Noto Sans;
    outline: 0px;
    padding-left: 10px;
    background-color: #e4ecfcff;
}

/* input2 */
.new_project_entry2_input {
    height: 30px;
    border: 0px;
    padding-left: 10px;
    border-radius: 5px;
    margin-left: 20px;
    width: 150px;
}

.new_project_entry2_input:focus {
    font-family: Noto Sans;
    outline: 0px;
    padding-left: 10px;
    background-color: #e4ecfcff;
}

/* input3 */
.new_project_entry3_input {
    height: 30px;
    border: 0px;
    padding-left: 10px;
    border-radius: 5px;
    margin-left: 20px;
    width: 150px;
}

.new_project_entry3_input:focus {
    font-family: Noto Sans;
    outline: 0px;
    padding-left: 10px;
    background-color: #e4ecfcff;
}

/* input4 */
.new_project_entry4_input {
    height: 30px;
    border: 0px;
    padding-left: 10px;
    border-radius: 5px;
    margin-left: 20px;
    width: 150px;
}

.new_project_entry4_input:focus {
    font-family: Noto Sans;
    outline: 0px;
    padding-left: 10px;
    background-color: #e4ecfcff;
}

/* input5 */
.new_project_entry5_input {
    height: 30px;
    border: 0px;
    padding-left: 10px;
    border-radius: 5px;
    margin-left: 20px;
    width: 150px;
}

.new_project_entry5_input:focus {
    font-family: Noto Sans;
    outline: 0px;
    padding-left: 10px;
    background-color: #e4ecfcff;
}

/* input6 */
.new_project_entry6_input {
    height: 30px;
    border: 0px;
    padding-left: 10px;
    border-radius: 5px;
    margin-left: 20px;
    width: 150px;
}

.new_project_entry6_input:focus {
    font-family: Noto Sans;
    outline: 0px;
    padding-left: 10px;
    background-color: #e4ecfcff;
}

/* input7 */
.new_project_entry7_input {
    height: 30px;
    border: 0px;
    padding-left: 10px;
    border-radius: 5px;
    margin-left: 20px;
    width: 150px;
}

.new_project_entry7_input:focus {
    font-family: Noto Sans;
    outline: 0px;
    padding-left: 10px;
    background-color: #e4ecfcff;
}

/* input8 */
.new_project_entry8_input {
    height: 30px;
    border: 0px;
    padding-left: 10px;
    border-radius: 5px;
    margin-left: 20px;
    width: 150px;
}

.new_project_entry8_input:focus {
    font-family: Noto Sans;
    outline: 0px;
    padding-left: 10px;
    background-color: #e4ecfcff;
}

.new_project_entries_submit {
    margin-top: 30px;
    width: 100px;
    margin-left: 350px;
    margin-right: 350px;
    height: 30px;
    font-family: Noto Sans;
    background-color: #5bc0deff;
    border: 0px;
    border-radius: 5px;
    color: white;
    box-shadow: 1px 1px 10px 1px #5bc0deff;
}

.new_project_entries_submit:hover {
background-color: #d898be;
box-shadow: 1px 1px 10px 1px #d898be;
}