.circle {
  position: absolute;
  height: 500px;
  width: 500px;
  margin-left: -400px;
  background-color: white;
  border-radius: 50%;
}

.circle_2 {
  position: absolute;
  background-color: #dcdcdcff;
  height: 500px;
  width: 500px;
  border: 1px solid #dcdcdcff;
  margin-left: -400px;
  border-radius: 50%;
  opacity: 100%;
  animation: border_increase;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes border_increase {
  100% {
    height: 800px;
    width: 800px;
    opacity: 0%;
  }
}

.login_body {
  background-image: url("./Images/study_table.jpg");
  background-size: cover;
  width: 100vw;
  height: 100vh;
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_img {
  /* width: 100px; */
  height: 100px;
  margin-bottom: 10px;
  /* clip-path: circle(); */
}

.login_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 400px;
  width: 400px;
  border-radius: 10px;
  transform: translateZ(10px);
}

.login_heading {
  font-weight: 600;
  margin-bottom: 0px;
}

.sub_heading {
  color: #999999;
}

.login_email {
  font-family: Roboto;
  font-size: small;
  background-color: #dcdcdcff;
  border-radius: 50px;
  margin-top: 20px;
  width: 300px;
  height: 30px;
  border: none;
  outline: 0px;
  padding-left: 20px;
}

.login_email_motion {
  font-family: Roboto;
  font-size: small;
  background-color: #dcdcdcff;
  border-radius: 50px;
  margin-top: 20px;
  width: 300px;
  height: 30px;
  border: none;
  outline: 0px;
  padding-left: 20px;
  animation: placeholder_motion;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

@keyframes placeholder_motion {
  100% {
    background-color: #f1f3f4ff;
    padding-left: 50px;
  }
}

#login_name_logo {
  position: absolute;
  margin-top: 160px;
  margin-left: -300px;
  color: #5bc0deff;
  opacity: 0%;
}

#login_name_logomotion {
  position: absolute;
  margin-top: 160px;
  margin-left: -300px;
  color: #5bc0deff;
  animation: logoSlide;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

@keyframes logoSlide {
  100% {
    margin-left: -250px;
    color: #5bc0deff;
    opacity: 100%;
  }
}

.login_password {
  font-family: Roboto;
  font-size: small;
  background-color: #dcdcdcff;
  border-radius: 50px;
  margin-top: 20px;
  width: 300px;
  height: 30px;
  border: none;
  outline: 0px;
  padding-left: 20px;
}

.login_password_motion {
  font-family: Roboto;
  font-size: small;
  background-color: #dcdcdcff;
  border-radius: 50px;
  margin-top: 20px;
  width: 300px;
  height: 30px;
  border: none;
  outline: 0px;
  padding-left: 20px;
  animation: placeholder_motion;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

#login_password_logo {
  position: absolute;
  margin-top: 210px;
  margin-left: -300px;
  color: #5bc0deff;
  opacity: 0%;
}

#login_password_logomotion {
  position: absolute;
  margin-top: 210px;
  margin-left: -300px;
  color: #5bc0deff;
  animation: logoSlide;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.account_login_button {
  margin-top: 30px;
  width: 300px;
  height: 40px;
  background-image: url("./Images/skyhigh.jpg");
  color: white;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
}

.account_login_button_link {
  cursor: auto;
}

.text_decoration_off {
  text-decoration: none;
}

.login_forgot_password {
  color: #5bc0de;
  font-size: small;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0px;
  font-family: Noto Sans;
}
