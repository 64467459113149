.formfile {
  height: 100%;
  font-size: small;
}

.formfile_card {
  padding: 20px;
  width: 95%;
  margin-left: 30px;
  max-height: 70vh;
  overflow-y: scroll;
}

/* ::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background-color: #dcdcdcff;
}

::-webkit-scrollbar-thumb {
    background-color: #a3a3a3ff;
    border-radius: 10px;
    border: 3px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #777777ff;
} */
.image-preview {
  height: 20%;
  width: 20%;
  padding: 0.2rem;
  background-color: lightgrey;
  border-radius: 7px;
  margin-bottom: 10px;
}
.image-container {
  display: flex;
  flex-direction: column;
}
.image-label {
  width: 40%;
  background-color: lightgrey !important;
}
.MuiFilledInput-underline:before {
  content: none !important;
}

.form-card {
  padding: 2%;
  margin: 2% 2% 2% 2%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px -1px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 10px;
  background-color: #ffffff;
}
