.position {
    position: relative;
    margin-left: -20px;
    padding-bottom: 15px;

}

.absolute{
    position: absolute;
    left: 100px;
    top: 10px;
    /* background-color: aquamarine; */
    border-radius: 50%;
    height: 20px;
    width: 20px;
}