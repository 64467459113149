.header_navbar {
  display: flex;
  align-items: center;
  padding: 5px 0px;
  background-color: white;
  border-bottom: 1px solid #dcdcdcff;
  justify-content: space-between;
  border-left: 1px solid #dcdcdcff;
  /* min-height: 100px; */
}

.header_navbar_Appicon {
  margin-left: 20px;
  border-radius: 5px;
  margin-right: 20px;
}

#header_search_logo {
  color: #999999;
  /* margin-left: 70px; */
  background-color: #f1f3f4;
  padding: 9px;
  /* padding-right: 10px; */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.header_navbar_search {
  border: none;
  background-color: #f1f3f4;
  /* padding: 5px; */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 650px;
  font-family: Roboto;
}

.header_navbar_search:focus {
  outline: #e4ecfc;
  background-color: #dcdcdc;
}

.header_navbar_search:hover {
  outline: #e4ecfc;
  background-color: #dcdcdc;
}

.header_navbar_search::placeholder {
  font-size: 14px;
}

.header_navbar_autodiv {
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#header_bell_icon {
  background-color: #f1f3f4;
  padding: 10px;
  border-radius: 5px;
}

#header_bell_icon:hover {
  background-color: #dcdcdcff;
}

#header_gear_logo {
  background-color: #f1f3f4;
  padding: 10px;
  border-radius: 5px;
  margin-left: 10px;
}

#header_gear_logo:hover {
  background-color: #dcdcdcff;
}

#header_logout {
  background-color: #f1f3f4;
  padding: 10px;
  border-radius: 5px;
  margin-left: 10px;
}

#header_logout:hover {
  background-color: #dcdcdcff;
}

.header_navbar_user1 {
  clip-path: circle();
  width: 30px;
  margin-left: 10px;
}

.header_navbar_modulename {
  text-transform: capitalize;
  font-size: small;
  margin-left: 10px;
}


.dropdown {
  top: 50px;
  background: white;
  right: 0px;
  position: absolute;
  z-index: 1;
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root{
  padding: 0px !important;
}
           