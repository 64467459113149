.reset-password-container {
    max-width: 500px;
    min-width: 500px !important;
    margin: 30px auto;
    padding: 20px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    /* background-color: #f1f5ff; */
    /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); */

}

.reset-form-container {
    text-align: left;
}

.reset-form {
    display: flex;
    flex-direction: column;
}

label {
    font-weight: bold;
    margin-bottom: 5px;
}

input {
    padding: 10px;
    /* margin-bottom: 10px; */
    border: 1px solid #ccc;
    border-radius: 5px;
}

.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.reset-button, .submit-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.reset-button {
    background-color: #f0ad4e;
    color: white;
}

.submit-button {
    background-color: #5cb85c;
    color: white;
}
