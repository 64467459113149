.applicationFormPanel {
  background-color: #dcdcdc;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  /* background-image: radial-gradient(#a3a3a3, #eeeeee); */
}

.AFformpage {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.507);
  width: 60vw;
  height: 90vh;
  border-radius: 10px;
  /* box-shadow: 1px 1px 10px 1px #a3a3a3ff; */
  overflow: scroll;
}

.AFmoduleHeading {
  /* background-color: #dcdcdc; */
  border-bottom: 1px solid #dcdcdc;
  color: #222222;
  width: 100%;
  padding: 10px 0px 10px 10px;
  font-size: large;
  letter-spacing: 1px;
  /* border-radius: 5px; */
}

.AFDiv {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  color: #555555ff;
  background-color: white;
  margin: 10px 0px 40px 40px;
}

.AFselectModule {
  width: 100%;
  border: 0px;
  border-radius: 5px;
  font-size: small;
  padding: 5px 0px 5px 5px;
  background-color: white;
  border: 1px solid #dcdcdc;
}

.AFselectModule::placeholder {
  color: #a3a3a3;
}

.AFselectModule:focus {
  outline: 1px solid #5bc0deff;
}

.AFmoduleNameDiv {
  margin-top: 20px;
}

.AFmoduleName {
  width: 100%;
  border: 0px;
  border-radius: 5px;
  font-size: small;
  padding: 5px 0px 5px 5px;
  background-color: white;
  border: 1px solid #dcdcdc;
}

.AFmoduleName:focus {
  outline: 1px solid #5bc0deff;
}

.AFmoduleDiscriptionDiv {
  margin-top: 20px;
}

.AFmoduleDiscription {
  width: 100%;
  border: 0px;
  border-radius: 5px;
  font-size: small;
  padding: 5px 0px 40px 5px;
  background-color: white;
  border: 1px solid #dcdcdc;
}

.AFmoduleDiscription:focus {
  outline: 1px solid #5bc0deff;
}

.AFmoduleIconDiv {
  margin-top: 20px;
}

.AFmoduleIcon {
  width: 100%;
  border: 0px;
  border-radius: 5px;
  font-size: small;
  padding: 5px 0px 5px 5px;
  background-color: white;
  border: 1px solid #dcdcdc;
}

/* .AFmodulePathDiv {
  margin-top: 20px;
} */

.AFtagButton {
  font-size: small;
  margin: 0px 5px 0px 5px;
  border: 1px solid #dcdcdc;
  cursor: pointer;
}

.AFtagButton:hover {
  background-color: #dcdcdc;
}

#AFtagCloseIcon {
  font-size: 10px;
}

.AFmodulePath {
  width: 100%;
  border: 0px;
  border-radius: 5px;
  font-size: small;
  padding: 5px 0px 5px 5px;
  background-color: white;
  border: 1px solid #dcdcdc;
}

.AFmodulePath:focus {
  outline: 1px solid #5bc0deff;
}

.AFmoduleStatusDiv {
  margin-top: 20px;
}

.AFmoduleStatus {
  width: 100%;
  border: 0px;
  border-radius: 5px;
  font-size: small;
  padding: 5px 0px 5px 5px;
  background-color: white;
  border: 1px solid #dcdcdc;
}

.AFmoduleStatus:focus {
  outline: 1px solid #5bc0deff;
}

.AFmodulebuttonDiv {
  margin-top: 40px;
}

.AFmodulebutton {
  width: 100%;
  border: 0px;
  border-radius: 5px;
  font-size: small;
  padding: 5px 0px 5px 5px;
  background-color: #a4cde1ff;
  color: white;
}

.AFmodulebutton:hover {
  background-color: #5bc0deff;
  cursor: pointer;
}

.AFmodulebutton:active {
  outline: 0px;
  border: 0px;
}
